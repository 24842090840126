import TagManager from 'react-gtm-module';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// Create a client
const queryClient = new QueryClient();

// Plugins
import 'i18n';
import * as Sentry from '@sentry/react';
import { init as initFullStory } from '@fullstory/browser';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

// Context provider
import AppContextProvider from 'providers/AppContextProvider';

// Components
import Maintenance from 'components/Maintenance/Maintenance';
import PageLoader from 'components/Layout/PageLoader/PageLoader';
import RequireAuth from 'components/Login/RequireAuth/RequireAuth';
import ErrorFallback from 'components/Layout/ErrorFallback/ErrorFallback';
import OutdatedBrowser from 'components/OutdatedBrowser/OutdatedBrowser';

// Lazy Load Public Components
const Layout = lazyLoadComponent(() => import('components/Layout/Layout'), 'Layout');
const NotFound = lazyLoadComponent(() => import('components/Layout/NotFound/NotFound'), 'NotFound');

const Welcome = lazyLoadComponent(() => import('components/Welcome/Welcome'), 'Welcome');
const EmailPreferences = lazyLoadComponent(() => import('components/EmailPreferences/EmailPreferences'), 'EmailPreferences');
const StudioOrganization = lazyLoadComponent(() => import('components/Studio/Organization/Organization'), 'StudioOrganization');

// Lazy Load Auth Components
const NavigationLayout = lazyLoadComponent(() => import('components/Layout/NavigationLayout/NavigationLayout'), 'NavigationLayout');

const User = lazyLoadComponent(() => import('components/User/User'), 'User');
const Galleries = lazyLoadComponent(() => import('components/Galleries/Galleries'), 'Galleries');
const Gallery = lazyLoadComponent(() => import('components/Gallery/Gallery'), 'Gallery');
const AdvancePay = lazyLoadComponent(() => import('components/AdvancePay/AdvancePay'), 'AdvancePay');
const StorefrontLayout = lazyLoadComponent(() => import('components/Storefront/StorefrontLayout'), 'StorefrontLayout');
const Checkout = lazyLoadComponent(() => import('components/Checkout/Checkout'), 'Checkout');
const OrderConfirmation = lazyLoadComponent(() => import('components/Checkout/Confirmation/Confirmation'), 'OrderConfirmation');

// Helpers
import lazyLoadComponent from 'utils/lazyLoadComponent';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';

// Styles
import 'index.css';

// Constants
const APP_VERSION = __VERSION__; // This is the version from the Vite config
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const APP_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT;
const FULLSTORY_ORG_ID = import.meta.env.VITE_FULLSTORY_ORG_ID;
const GOOGLE_TAG_ID = import.meta.env.VITE_GOOGLE_TAG_ID;
const GOOGLE_TAG_AUTH = import.meta.env.VITE_GOOGLE_TAG_AUTH || '';
const GOOGLE_TAG_PREVIEW = import.meta.env.VITE_GOOGLE_TAG_PREVIEW || '';
const MAINTENANCE = import.meta.env.VITE_MAINTENANCE === '1'; // Maintenance mode disables everything else
const FLAGSMITH_ENV_ID = import.meta.env.VITE_FLAGSMITH_ENV_ID;

// Init Sentry error monitoring
if (SENTRY_DSN && APP_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.05,
    environment: APP_ENVIRONMENT,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.0,
    initialScope: (scope) => {
      scope.setTags({ appVersion: APP_VERSION });
      return scope;
    },
    integrations: [
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllText: false
      })
    ],
    beforeSend: (event, hint) => {
      // @ts-ignore
      const errorMessage = hint?.originalException?.message || '';
      const { breadcrumbs, exception } = event;

      if (exception?.values?.some((value) => value.type === 'TypeError' && value.value?.includes('Load failed'))) {
        // Filter out invalid files after deploy
        return null;
      }

      if (breadcrumbs?.some((a) => a.data?.url?.includes('analytics')) || breadcrumbs?.some((a) => a.data?.url?.includes('translate.googleapis.com'))) {
        // Filter out external errors
        return null;
      }

      if (
        errorMessage.includes('Page already has an active payment session') ||
        errorMessage.includes('ceCurrentVideo.currentTime') ||
        errorMessage.includes('chrome-extension') ||
        errorMessage.includes('Unable to preload CSS') ||
        errorMessage.includes('ResizeObserver loop completed with undelivered notifications') ||
        errorMessage.includes('Importing a module script failed.') ||
        errorMessage.includes('Failed to fetch dynamically imported module') ||
        errorMessage.includes('PerformanceObserverInit') ||
        errorMessage.includes('cancelled') ||
        errorMessage.includes('Failed to fetch') ||
        errorMessage.includes('Non-Error promise rejection captured') ||
        errorMessage.includes('A network error occurred')
      ) {
        // Filter by message
        return null;
      }

      return event;
    }
  });
}

// Init Google Tag Manager
if (GOOGLE_TAG_ID) {
  const tagManagerArgs = {
    gtmId: GOOGLE_TAG_ID,
    ...(GOOGLE_TAG_AUTH && GOOGLE_TAG_PREVIEW ? { auth: GOOGLE_TAG_AUTH, preview: GOOGLE_TAG_PREVIEW } : {})
  };

  TagManager.initialize(tagManagerArgs);
}

// Init FullStory
if (FULLSTORY_ORG_ID) {
  initFullStory({ orgId: FULLSTORY_ORG_ID, devMode: APP_ENVIRONMENT !== 'production' });
}

// Init Flagsmit
if (FLAGSMITH_ENV_ID) {
  flagsmith
    .init({
      environmentID: FLAGSMITH_ENV_ID,
      cacheFlags: true,
      enableAnalytics: APP_ENVIRONMENT === 'production'
    })
    .catch((error) => {
      console.error('Flagsmith error:', error);
    });
}

const container = document.getElementById('app');

const RequireAuthNavLayout = () => (
  <RequireAuth>
    <NavigationLayout />
  </RequireAuth>
);

const App = () => {
  if (MAINTENANCE) return <Maintenance />;

  return (
    <FlagsmithProvider flagsmith={flagsmith}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <BrowserRouter>
            <OutdatedBrowser />
            <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
              <Suspense fallback={<PageLoader />}>
                <Routes>
                  {/* Onboarding Routes */}
                  <Route path="/" element={<Layout shouldShowAnimationBG={true} />}>
                    <Route path="" element={<Welcome />} />
                    <Route path="/g/:accessCodes" element={<Welcome />} />
                    <Route path="login" element={<Welcome />} />
                  </Route>

                  {/* Auth & Nav Routes */}
                  <Route path="/" element={<RequireAuthNavLayout />}>
                    <Route path="galleries" element={<Galleries />} />
                    <Route path="gallery/:galleryPath?/*" element={<Gallery />} />
                    <Route path="advancepay/:galleryId" element={<AdvancePay />} />
                    <Route path="storefront/:storefrontGalleryId?/:categoryId?" element={<StorefrontLayout />} />
                    <Route path="checkout/:galleryId?" element={<Checkout />} />
                    <Route path="order-confirmation/:orderId?" element={<OrderConfirmation />} />
                    <Route path="user/*">
                      <Route path="my-orders/:orderId" element={<User />} />
                      <Route path="*" element={<User />} />
                    </Route>
                  </Route>

                  {/* Public Routes */}
                  <Route path="/" element={<Layout />}>
                    <Route path="unsubscribe" element={<EmailPreferences />} />
                    <Route path="resubscribe" element={<EmailPreferences />} />
                    <Route path="/s">
                      <Route path=":studioPath/:organizationPath" element={<StudioOrganization />} />
                      <Route path="*" element={<NotFound publicPath={true} />} />
                    </Route>
                  </Route>

                  {/*Route not found */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </AppContextProvider>
        <div className="text-body">
          <ReactQueryDevtools initialIsOpen={false} />
        </div>
      </QueryClientProvider>
    </FlagsmithProvider>
  );
};

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
